@tailwind base ;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap');

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

* {
  scrollbar-width: thin;
  scroll-behavior: smooth;
  font-family: 'Noto Sans JP', sans-serif;
}

body {
  background-color: #D9E7FB;
}

.tableBody::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Style the scrollbar thumb */
.tableBody::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 8px;
}

/* Add hover effect to the scrollbar thumb */
.tableBody::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Style the scrollbar when dragged */
.tableBody::-webkit-scrollbar-thumb:active {
  background-color: #333;
}

.tableBody {
  background: #D9E7FB;
}

/* * {
  outline: 1px solid red;
} */


.container-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}


.checkbox-checkmark {
  position: absolute;
  border: 1.9px solid #203375;
  top: 4px;
  left: 0;
  height: 12px;
  width: 12px;
  border-radius: 2px;
  background-color: #eee;
}


.container-checkbox:hover input ~ .checkbox-checkmark {
  background-color: #ccc;
}


.container-checkbox input:checked ~ .checkbox-checkmark {
  background-color: #fff;
}


.checkbox-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}


.container-checkbox input:checked ~ .checkbox-checkmark:after {
  display: block;
}


.container-checkbox .checkbox-checkmark:after {
  left: 3px;
  top: -1px;
  width: 4px;
  height: 8px;
  border: solid black;
  border-width: 0 1.9px 1.9px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}